import { defineStore } from 'pinia'

export const useLoginStore = defineStore({
  id: 'login',
  state: () => ({
    nextUrl: null as string | null,
    targetRollId: null as string | null,
    email: null as string | null,
    // Reassign roll from a guest to a real user
    mergeWithUserId: null as string | null,
  }),
  actions: {},
  getters: {},
})
